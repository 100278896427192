<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">充电桩管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/charging/chargingRefund">退款管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb> 
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="桩号" prop="deviceNo">
        <el-input type="text" size="mini" v-model="queryModel.deviceNo"></el-input>
      </el-form-item>
      <el-form-item label="时间范围" prop="rangeTime">
        <el-date-picker
        size="mini"
        v-model="queryModel.rangeTime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm"
        format="yyyy-MM-dd HH:mm">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="时间选择" prop="name">
        <el-select v-model="queryModel.dateSelect" clearable placeholder="请选择" size="mini">
            <el-option
            v-for="item in dateSelectList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="deviceNo"
        label="桩号"
        width="150"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="channelNo"
        label="通道"
        fixed="left"
      ></el-table-column>
     <el-table-column
        prop="electricQuantity"
        label="预估电量"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="refundFee"
        label="退款金额"
      ></el-table-column>
      <el-table-column
        prop="endTime"
        label="时间"
      ></el-table-column>
      <el-table-column
        prop="templateName"
        label="充电模式"
      ></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="{row}">
          <el-link type="primary" :underline="false" @click="handleEdit(row)">查看详情</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <chargingRecord-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></chargingRecord-detail>
  </div>
</template>
<script>
import chargingRecordDetail from "./chargingRecord-detail";
import chargingRecordApi from "@/api/base/chargingRecord";
import chargingStationApi from "@/api/base/chargingStation";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "chargingChargingRecordList",
  data() {
    var self = this;

    return {
      queryModel: {
        deviceNo: "",
        stationId: "",
        dateSelect:"",
        isPowerCut:"",
        rangeTime:"",
      },
      dateSelectList:[{
          label:"当天",
          value:"1",
      },
      {
          label:"当月",
          value:"2"
      },
      {
          label:"上月",
          value:"3"
          
      }],
      isPowerCutList:[{
          label:"否",
          value:"0",
      },
      {
          label:"是",
          value:"1",
      }],
      stationList:[],
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showModal2: false,
      modalTitle2: "",
      showModal3: false,
      modalTitle3: "",
      businessKey: "",
      selectedRow: {},
    };
  },
  methods: {
    handleBindClass(row) {
      this.selectedRow = row;
      this.showBindModal = true;
    },
    handleCloseLive() {
      if(this.player!=null){
        this.player.pause();
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("rangeTime", self.queryModel.rangeTime);
      formData.append("dateSelect", self.queryModel.dateSelect);
      formData.append("isRefund", "1");


      chargingRecordApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "查看详情";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          chargingRecordApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        chargingRecordApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  created() {
    chargingStationApi.list().then((response)=>{
      var jsonData = response.data;
      if (jsonData.result) {
        this.stationList = jsonData.data;
      }
    });
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "chargingRecord-detail": chargingRecordDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.capture-image{
  width:160px;
  height: 90px;
  object-fit: container;
}
</style>